@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;1,700&family=Poppins&family=Raleway:wght@400;600&display=swap');

*{
    font-family: raleway, sans-serif;
    color: white;
}
.poppins{
    font-family: poppins, sans-serif;
}
.montserrat{
    font-family: 'Montserrat', sans-serif;
}
.brandLogo{
    width: 5vh;
    height: auto;
}
.white{
    color: white;
}
.para{
font-size: 15px;
color: #d8d8d8;
line-height: 22px;
}
body{
    background-color: #1a1a1a;
}
a{
    text-decoration: none;
}
.navbarItems a{
    color: white !important;
    font-weight: 600;
}
.navbarItems a:hover{
    color: rgb(171, 88, 248) !important;
    font-weight: 600;
}
.darkTheme{
    background-color: #202020;
    border:  1px solid #242424 ;
}
.trending, .slideBar, .headings{
    color: white !important;
    font-weight: 600;
    font-size: 15px !important;
}
.trending-text{
    color: rgb(44, 65, 255) !important;
    font-weight: 600;
    font-size: 15px !important;
}
.Hcenter{
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
}
.author{
    font-size: calc(0.5rem + 0.3vw) !important;
        color: rgb(48, 68, 252) !important;
  } 
  .authorLogo{
    width: 3vh;
    height: auto;
  }
  .youtube-author{
    font-size: calc(0.5rem + 0.5vw) !important;
  }
.heading{
    font-size:calc(0.5rem + 1vw) !important;
    font-weight: 600;
}
.info{
    font-size:calc(0.6rem + 0.5vw) !important;
}


/* For lg in bootstrap  */

@media only screen and (max-width:992px) {
    .author{
        font-size:calc(0.7rem + 0.7vw) !important;
    }

    .heading{
        font-size:calc(0.7rem + 1.5vw) !important;

    }
    .info{
        font-size:calc(0.6rem + 1vw) !important;
    }
    .youtube-author{
        font-size: calc(0.7rem + 0.5vw) !important;
      }
  }