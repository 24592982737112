.title{
    font-size:calc(1rem + 1.5vw) !important;
    font-weight: 600;
}
.mainTitle{
    font-size:calc(0.5rem + 1vw) !important;
    font-weight: 400;
}
@media only screen and (max-width:992px) {
    .title{
        font-size:calc(1.5rem + 2vw) !important;
    }
    .mainTitle{
        font-size:calc(0.8rem + 1vw) !important;
        font-weight: 400;
    }
  }